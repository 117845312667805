import axios from 'axios'
// 创建一个 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // url = base url + request url
  timeout: 10000 // request timeout
})

// request interceptor请求拦截器
service.interceptors.request.use(
  config => {
    // do something before request is sent 在发出请求前
    // console.log(config)
    return config
  },
  error => {
    // do something with request error 处理请求错误
    return Promise.reject(error)
  }
)

// response interceptor 响应拦截器
service.interceptors.response.use(
  response => {
    // 返回值 是失败的
    // if (response.data.isSuccess !== true) {
    //   if (res.data.code === -1) { // 错误码是-1 时
    //     return retryNotErrReq(res.config)
    //   } else {
    //     handleError(res.data.code, res.data.msg)
    //     return Promise.reject(new Error(res.data.msg || 'Error'))
    //   }
    // } else {
      // console.log(response)
      return response
    // }
  },
  error => {
     return Promise.reject(error)
  }
)

export default service