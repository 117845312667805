<template>
  <div class="footer">
    <div class="info">
      <p>了解更多</p>
      <p>供应商</p>
      <p>产品功能</p>
      <p>媒体资源</p>
    </div>
    <div class="info">
      <p>关于我们</p>
      <p>创始人</p>
      <p>我们的团队</p>
      <p>直供商</p>
    </div>
    <div class="info">
      <p>加入我们</p>
      <p>社会招聘</p>
      <p>校园招聘</p>
      <p>工作兼职</p>
    </div>
    <div class="info">
      <p>资质证书</p>
      <div class="img-box">
        <img src="" alt="" srcset="" />
        <img src="" alt="" srcset="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style langt="scss" scoped>
.footer {
  width: 100%;
  height: 421px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.info {
  width: 300px;
  height: 250px;
  display: flex;
  flex-direction: column;
  p {
    height: 34px;
    font-size: 24px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    line-height: 28px;
    &:first-child {
      font-weight: bold;
      color: #ffffff;
    }
  }
}
</style>
