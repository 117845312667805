var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"carousel-box"},[_c('el-carousel',{attrs:{"height":"908px"}},_vm._l((_vm.imgList),function(item,index){return _c('el-carousel-item',{key:index},[_c('img',{staticStyle:{"height":"100%","width":"100%"},attrs:{"src":item.imgFileUrl}})])}),1)],1),_c('div',{staticClass:"bottom-content"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"peo"},[_c('div',{staticClass:"main3"},[_vm._m(2),_c('div',{staticClass:"right-info"},_vm._l((_vm.correspondingPopulation),function(item,index){return _c('div',{key:index,staticClass:"info-item"},[_c('div',{staticClass:"info-title"},[_c('img',{staticClass:"icon",attrs:{"src":item.icon}}),_c('div',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"info-txt"},[_vm._v(_vm._s(item.txt))])])}),0)])]),_c('div',{staticClass:"platform"},[_c('div',{staticClass:"we"},[_vm._v("我们的平台")]),_c('div',{staticClass:"top-item-contoan"},_vm._l((_vm.businessBistrict),function(item,index){return _c('div',{key:index,staticClass:"top-item"},[_c('div',{staticClass:"t-icon"},[_c('img',{staticClass:"topicon",attrs:{"src":item.topicon}})]),_c('div',{staticClass:"toptxt"},[_vm._v(_vm._s(item.toptxt))])])}),0),_c('div',{staticClass:"business"},[_vm._v(" 服务商家的用户粘性与经营成本高，解决消费者1.5km生活需求连接与用户优惠，商家app是一个为商家服务客人的服务分享软件，消费者在商家接受服务有会员优惠，消费者可以分享会员将得到佣金，商家推广其他商家也是享有分成的软件，消费者通过软件可以选择自己喜欢的商家。 ")]),_vm._m(3),_vm._m(4)]),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main1"},[_c('div',{staticClass:"share"},[_c('div',{staticClass:"title"},[_c('img',{staticClass:"small",attrs:{"src":require("../assets/Slice10.png"),"alt":""}}),_c('div',[_vm._v("商圈共享平台")])]),_c('div',{staticClass:"main-txt"},[_vm._v(" 一个整合上下游、供应商资源链接商家、服务顾客的生活服务平台 ")]),_c('div',{staticClass:"home-sub-title"},[_c('img',{attrs:{"src":require("../assets/Slice11.png"),"alt":""}}),_c('div',[_vm._v("未来趋势")])]),_c('div',{staticClass:"sub-txt"},[_vm._v(" 分享经济、一种全新的商业模式、全新的商业革命。 ")]),_c('div',{staticClass:"home-sub-title"},[_c('img',{attrs:{"src":require("../assets/Slice12.png"),"alt":""}}),_c('div',[_vm._v("共享商圈")])]),_c('div',{staticClass:"sub-txt"},[_vm._v(" 它跨界组合异业联盟、互推客户、共享需求它跨界链接、资源对接、整合上游、满足下游。 ")])]),_c('div',{staticClass:"explain"},[_c('div',[_c('div',{staticClass:"explain-title"},[_vm._v(" 商圈是什么?     是我们独特的理念 ")]),_c('div',{staticClass:"explain-txt"},[_c('p',[_vm._v(" 是根植于社会区、服务于社区基层组织商圈性质:民商组合异业联盟互荐顾客共享需求 ")]),_vm._v(" 未来趋势——分享经济、一种全新的商业模式、全新的商业革命 共享商圈——它跨界组合异业联盟、互推客户、共享需求它跨界链接、资源对接、整合上游、满足下游 ")])]),_c('div',{staticClass:"rectangle"},[_c('div',{staticClass:"quarter-circle"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"demand-container"},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("../assets/Slice50.png"),"alt":""}})]),_c('div',{staticClass:"pic-txt"},[_c('div',{staticClass:"left-pic-txt"},[_c('div',{staticClass:"special"},[_c('img',{attrs:{"src":require("../assets/Slice6.png"),"alt":""}}),_c('span',[_vm._v("民商组合")])]),_c('div',{staticClass:"special"},[_c('img',{attrs:{"src":require("../assets/Slice7.png"),"alt":""}}),_c('span',[_vm._v("互荐顾客")])])]),_c('div',{staticClass:"right-pic-txt"},[_c('div',{staticClass:"special"},[_c('img',{attrs:{"src":require("../assets/Slice8.png"),"alt":""}}),_c('span',[_vm._v("商业表现")])]),_c('div',{staticClass:"special"},[_c('img',{attrs:{"src":require("../assets/Slice8.png"),"alt":""}}),_c('span',[_vm._v("共享需求")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-img-box"},[_c('div',{staticClass:"top-text"},[_c('img',{staticClass:"icon-img",attrs:{"src":require("../assets/Slice13.png"),"alt":""}}),_c('div',[_vm._v("对应人群")])]),_c('img',{staticClass:"bottom-img",attrs:{"src":require("../assets/Slice44.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tupian-box"},[_c('img',{staticClass:"img-1",attrs:{"src":require("../assets/Slice45.png"),"alt":""}}),_c('div',{staticClass:"tupian-middle-box"},[_c('img',{staticClass:"img-2",attrs:{"src":require("../assets/Slice46.png"),"alt":""}}),_c('img',{staticClass:"img-3",attrs:{"src":require("../assets/Slice47.png"),"alt":""}})]),_c('div',{staticClass:"tupian-right-box"},[_c('img',{staticClass:"img-4",attrs:{"src":require("../assets/Slice48.png"),"alt":""}}),_c('img',{staticClass:"img-5",attrs:{"src":require("../assets/Slice49.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"big"},[_c('div',{staticClass:"big-icon"},[_c('img',{attrs:{"src":require("../assets/Slice21.png"),"alt":""}})]),_c('div',{staticClass:"enterprise"},[_vm._v("1000+企业的信赖选择")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottomIcon"},[_c('img',{attrs:{"src":require("../assets/Snipaste.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-box"},[_c('div',{staticClass:"custom"},[_vm._v("现在选择我们，让您的每一个客户更满意")]),_c('div',{staticClass:"register"},[_c('input',{attrs:{"placeholder":"请输入你的手机号"}}),_c('div',{staticClass:"free"},[_vm._v("免费注册")])])])
}]

export { render, staticRenderFns }