import request from '@/utils/request';

// 拉取平台信息----------------------------------------------
export function getwebsideinfo(params) {
    return request({
        url: "/website/info/get",
        method: 'get',
        params
    })
}
// 获取分类----------------------------------------------
export function getcategoryList(data) {
    return request({
        url: "/news/category/list/all",
        method: 'post',
        data
    })
}
// 获取新闻----------------------------------------------
export function getUserList(data) {
    return request({
        url: '/news/query',
        method: 'post',
        data
    })
}
// 获取新闻详情
export function getnewsinfo(params) {
    return request({
        url: '/news/query/info',
        method: 'get',
        params
    })
}
// 申请加盟接口----------------------------------------------
export function joincreate(data) {
    return request({
        url: '/join/create',
        method: 'post',
        data
    })
}
// 系统文件----------------------------------------------
export function getfile(params) {
    return request({
        url: '/system/file/preview',
        method: 'get',
        params
    })
}
// 轮播图接口----------------------------------------------
export function getcarousel(params) {
    return request({
        url: '/carousel/setting/listAll',
        method: 'get',
        params
    })
}