<template>
  <div id="app">
    <Main />
  </div>
</template>
<script>
import Main from "./views/Main.vue";
export default {
  components: {
    Main,
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  height: 100%;
}
</style>
