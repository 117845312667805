<template>
  <div class="container">
    <div class="sanjiao"></div>
    <div class="top-info">
      <div class="logo">
        <img :src="image" alt="" />
      </div>
      <div class="info">
        <div class="info-item">
          <img src="../assets/Slice22.png" alt="" ref="image" />
          <div>{{ number }}</div>
        </div>
        <div class="info-item">
          <img src="../assets/Slice23.png" alt="" />
          <div>{{ number2 }}</div>
        </div>
      </div>
    </div>
    <div class="content">
      <nav>
        <router-link to="/home">首页</router-link>
        <router-link to="/about">携手合作</router-link>
        <!-- <router-link to="/contact">联系我们</router-link> -->
        <RouterLink to="/AboutUs">关于我们</RouterLink>
        <RouterLink to="/Newview">新闻资讯</RouterLink>
      </nav>
      <router-view></router-view>
    </div>
    <Footer />
    <div class="bei">
      <div class="b-footertxt">
        Copyright &copy; 广东自由丽人网络科技有限公司 版权所有 丨
        <span class="beianicon">
          <img src="../assets/备案图标.png" alt="" />
        </span>
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >粤ICP备2024168311号</a
        >
        <span class="beianicon">
          <img src="../assets/备案图标.png" alt="" />
        </span>
        <a
          href="https://beian.mps.gov.cn/#/query/webSearch?code=44011102483736"
          rel="noreferrer"
          target="_blank"
        >
          粤公网安备44011102483736</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { getwebsideinfo } from "@/api/new";
import Footer from "@/components/Footer.vue";
import { Image } from "element-ui";
export default {
  components: {
    Footer,
    Image,
  },
  data() {
    return {
      // image: "../assets/Slice22.png",
      // number: "13221344657@fg.com",
      // number2: "13221344657",
      image: "",
      number: "",
      number2: "",
    };
  },
  created() {
    this.getwebsideinfo();
  },
  methods: {
    getwebsideinfo() {
      getwebsideinfo().then((res) => {
        console.log(res);
        this.image = res.data.body.websiteInfo.logeFileUrl;
        this.number = res.data.body.websiteInfo.customerServiceMail;
        this.number2 = res.data.body.websiteInfo.customerServiceTel;
      });
    },
  },
};
</script>
<style langt="scss" scoped>
.container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .top-info {
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 6.23% 0 4.79%;
    color: #da007f;
    .logo {
      img {
        width: 200px;
        height: 54px;
      }
    }
    .info {
      display: flex;
      align-items: center;
      .info-item {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 28px;
        img {
          margin-right: 10px;
          height: 30px;
        }
        & + .info-item {
          margin-left: 20px;
        }
      }
    }
  }
  .sanjiao {
    position: absolute;

    z-index: -1;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-left: 1420px solid transparent;
    border-top: 870px solid #da007f;
  }
  .content {
    overflow: hidden;
    width: 100%;
    min-height: 800px;
    position: relative;
    nav {
      position: absolute;
      z-index: 9;
      top: 0;
      width: 91.98%;
      height: 88px;
      background: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px 20px 0 0;
      left: 50%;
      transform: translateX(-50%);
      a {
        width: 165px;
        height: 100%;
        line-height: 88px;
        text-align: center;
        font-weight: bold;
        color: #fff;
        font-size: 24px;
        text-decoration: none;
        & + a {
          margin-left: 72px;
        }
        &.router-link-exact-active {
          color: #da007f;
          background: #d9d9d9;
        }
      }
    }
  }
  .bei {
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0.8);
  }
  .b-footertxt {
    width: 1000px;
    margin: 10px auto 0;
    color: rgba(255, 255, 255, 0.85);
    font-size: 18px;
  }
  .b-footertxt a {
    color: rgba(255, 255, 255, 0.85);
    text-decoration: none;
  }
  .beianicon {
    width: 20px;
    height: 20px;
  }
  .beianicon img {
    width: 20px;
    height: 20px;
  }
}
</style>
