<template>
  <div class="home">
    <div class="carousel-box">
      <el-carousel height="908px">
        <el-carousel-item v-for="(item, index) in imgList" :key="index">
          <img :src="item.imgFileUrl" style="height: 100%; width: 100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="bottom-content">
      <div class="main1">
        <div class="share">
          <div class="title">
            <img src="../assets/Slice10.png" alt="" class="small" />
            <div>商圈共享平台</div>
          </div>
          <div class="main-txt">
            一个整合上下游、供应商资源链接商家、服务顾客的生活服务平台
          </div>
          <div class="home-sub-title">
            <img src="../assets/Slice11.png" alt="" />
            <div>未来趋势</div>
          </div>
          <div class="sub-txt">
            分享经济、一种全新的商业模式、全新的商业革命。
          </div>
          <div class="home-sub-title">
            <img src="../assets/Slice12.png" alt="" />
            <div>共享商圈</div>
          </div>
          <div class="sub-txt">
            它跨界组合异业联盟、互推客户、共享需求它跨界链接、资源对接、整合上游、满足下游。
          </div>
        </div>
        <div class="explain">
          <div>
            <div class="explain-title">
              商圈是什么?&nbsp;&nbsp;&nbsp;&nbsp; 是我们独特的理念
            </div>
            <div class="explain-txt">
              <p>
                是根植于社会区、服务于社区基层组织商圈性质:民商组合异业联盟互荐顾客共享需求
              </p>
              未来趋势——分享经济、一种全新的商业模式、全新的商业革命
              共享商圈——它跨界组合异业联盟、互推客户、共享需求它跨界链接、资源对接、整合上游、满足下游
            </div>
          </div>
          <div class="rectangle">
            <div class="quarter-circle"></div>
          </div>
        </div>
      </div>

      <div class="demand-container">
        <div class="pic">
          <img src="../assets/Slice50.png" alt="" />
        </div>
        <div class="pic-txt">
          <div class="left-pic-txt">
            <div class="special">
              <img src="../assets/Slice6.png" alt="" />
              <span>民商组合</span>
            </div>
            <div class="special">
              <img src="../assets/Slice7.png" alt="" />
              <span>互荐顾客</span>
            </div>
          </div>
          <div class="right-pic-txt">
            <div class="special">
              <img src="../assets/Slice8.png" alt="" />

              <span>商业表现</span>
            </div>
            <div class="special">
              <img src="../assets/Slice8.png" alt="" />
              <span>共享需求</span>
            </div>
          </div>
        </div>
      </div>
      <div class="peo">
        <div class="main3">
          <div class="left-img-box">
            <div class="top-text">
              <img src="../assets/Slice13.png" alt="" class="icon-img" />
              <div>对应人群</div>
            </div>
            <img src="../assets/Slice44.png" class="bottom-img" />
          </div>
          <div class="right-info">
            <div
              class="info-item"
              v-for="(item, index) in correspondingPopulation"
              :key="index"
            >
              <div class="info-title">
                <img :src="item.icon" class="icon" />
                <div>{{ item.title }}</div>
              </div>
              <div class="info-txt">{{ item.txt }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="platform">
        <div class="we">我们的平台</div>
        <div class="top-item-contoan">
          <div
            class="top-item"
            v-for="(item, index) in businessBistrict"
            :key="index"
          >
            <div class="t-icon">
              <img :src="item.topicon" class="topicon" />
            </div>
            <div class="toptxt">{{ item.toptxt }}</div>
          </div>
        </div>
        <div class="business">
          服务商家的用户粘性与经营成本高，解决消费者1.5km生活需求连接与用户优惠，商家app是一个为商家服务客人的服务分享软件，消费者在商家接受服务有会员优惠，消费者可以分享会员将得到佣金，商家推广其他商家也是享有分成的软件，消费者通过软件可以选择自己喜欢的商家。
        </div>
        <div class="tupian-box">
          <img src="../assets/Slice45.png" alt="" class="img-1" />
          <div class="tupian-middle-box">
            <img src="../assets/Slice46.png" alt="" class="img-2" />
            <img src="../assets/Slice47.png" alt="" class="img-3" />
          </div>
          <div class="tupian-right-box">
            <img src="../assets/Slice48.png" alt="" class="img-4" />
            <img src="../assets/Slice49.png" alt="" class="img-5" />
          </div>
        </div>
        <div class="big">
          <div class="big-icon">
            <img src="../assets/Slice21.png" alt="" />
          </div>
          <div class="enterprise">1000+企业的信赖选择</div>
        </div>
      </div>

      <div class="bottomIcon">
        <img src="../assets/Snipaste.png" />
      </div>
      <div class="bottom-box">
        <div class="custom">现在选择我们，让您的每一个客户更满意</div>
        <div class="register">
          <input placeholder="请输入你的手机号" />
          <div class="free">免费注册</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getcarousel } from "@/api/new";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      // 首页轮播图codeid
      positionCode:'1001',
      imgList: [
        // { url: require("@/assets/Group242.png") },
        // { url: require("@/assets/Group242.png") },
      ],
      correspondingPopulation: [
        {
          icon: require("@/assets/Slice14.png"),
          title: "直供商",
          txt: "减少资源在流通环节产生的成本，可以更好地满足市场和零售商的需求，有利于厂商的双赢。",
        },
        {
          icon: require("@/assets/Slice16.png"),
          title: "商家商铺",
          txt: "打造城市商业联盟和城市商圈平台，消费者可持卡在各联盟商户通用消费并享受折扣。",
        },
        {
          icon: require("@/assets/Slice16.png"),
          title: "消费群体",
          txt: "消费者会通过平台去了解消费习惯从而进行购买决策，消费者可以充分地感受到商家所提供的服务和产品优惠。",
        },
      ],
      businessBistrict: [
        {
          topicon: require("@/assets/Slice17.png"),
          toptxt: "附近美食",
        },
        {
          topicon: require("@/assets/Slice18.png"),
          toptxt: "美容美发",
        },
        {
          topicon: require("@/assets/Slice19.png"),
          toptxt: "购物优惠",
        },
        {
          topicon: require("@/assets/Slice20.png"),
          toptxt: "休闲娱乐",
        },
      ],
      bottomicon: [
        { url: require("@/assets/Slice51.png") },
        { url: require("@/assets/Slice52.png") },
        { url: require("@/assets/Slice53.png") },
        { url: require("@/assets/Slice54.png") },
        { url: require("@/assets/Slice55.png") },
        { url: require("@/assets/Slice56.png") },
        { url: require("@/assets/Slice57.png") },
        { url: require("@/assets/Slice58.png") },
        { url: require("@/assets/Slice59.png") },
        { url: require("@/assets/Slice60.png") },
        { url: require("@/assets/Slice61.png") },
        { url: require("@/assets/Slice62.png") },
        { url: require("@/assets/Slice63.png") },
        { url: require("@/assets/Slice64.png") },
        { url: require("@/assets/Slice65.png") },
        { url: require("@/assets/Slice66.png") },
        { url: require("@/assets/Slice67.png") },
        { url: require("@/assets/Slice68.png") },
        { url: require("@/assets/Slice69.png") },
        { url: require("@/assets/Slice70.png") },
        { url: require("@/assets/Slice71.png") },
        { url: require("@/assets/Slice72.png") },
        { url: require("@/assets/Slice73.png") },
        { url: require("@/assets/Slice74.png") },
      ],
    };
  },
  created(){
    this.getcarousel()
  },
  methods:{
    // 获取轮播图
    getcarousel(){
      let params={
        positionCode:this.positionCode
      }
      getcarousel(params).then((res)=>{
        console.log(res);
        this.imgList=res.data.body.carouselSettingList
      })
    }
  }
};
</script>
<style scoped lang="scss">
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  .carousel-box {
    width: 91.98%;
    height: 908px;
    margin: 0 auto;
  }
  ::v-deep .el-carousel {
    border-radius: 20px;
    overflow: hidden;
    height: 908px;
    .el-carousel-item {
      overflow: hidden;
    }
    .el-carousel__indicator {
      padding: 12px 17px;
      .el-carousel__button {
        width: 20px;
        height: 20px;
        background: rgba(255, 255, 255, 0.65);
        border-radius: 10px 10px 10px 10px;
      }
      &.is-active .el-carousel__button {
        width: 41px;
        height: 20px;
        background: #ff8bd0;
        border-radius: 10px 10px 10px 10px;
      }
    }
  }
  .share {
    padding-left: 206px;
    display: flex;
    flex-direction: column;
    float: left;
  }
  .small {
    width: 80px;
    height: 80px;
  }

  .bottom-content {
    width: 100%;
    padding: 0 120px;
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
    background-color: #fff;
    margin-top: 136px;
    overflow-x: hidden;
    .title {
      display: flex;
      align-items: center;
      div {
        white-space: nowrap;
        height: 56px;
        font-size: 40px;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        color: #000000;
        line-height: 56px;
        margin-left: 18px;
        vertical-align: middle;
      }
    }
    .main-txt {
      width: 450px;
      height: 69px;
      font-size: 24px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);
      line-height: 28px;
      padding-top: 29px;
    }

    .sub-txt {
      width: 567px;
      font-size: 20px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 23px;
      position: relative;
      margin-top: 1%;
    }
    .home-sub-title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -2.4%;
      margin-top: 2%;
      img {
        width: 44px;
        height: 44px;
        margin-left: -71%;
      }
      div {
        white-space: nowrap;
        height: 26px;
        font-size: 22px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 26px;
        margin-left: 18px;
        vertical-align: middle;
      }
    }
  }
  .explain {
    float: left;
    width: 781px;
    height: 365px;
    color: #ffffff;
    background: #da007f;
    border-radius: 40px 40px 40px 40px;
    opacity: 1;
    padding: 22px 24px 47px 33px;
    margin-left: 47px;
    // margin-top: -20%;
  }
  .explain-title {
    display: block;
    font: PingFang SC-Bold;
    font-size: 36px;
    font-weight: Bold;
    color: #ffffff;
    margin: 22px;
    line-height: 20px;
    white-space: pre-wrap;
    margin: 22px 0 0 22px;
  }
  .explain-txt {
    font-size: 24px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 50px;
    -webkit-background-clip: text;
  }
  .rectangle {
    position: relative;
    top: 11px;
    left: -80px;
    width: 99px;
    height: 61px;
    background: #da007f;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }

  .quarter-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 47px;
    height: 61px;
    background-color: #fff;
    border-radius: 0 0 47px 0;
  }
  .pic img {
    width: 510px;
    height: 454px;
  }
  .demand-container {
    display: flex;
    width: 1396px;
    // padding-left: 206px;
    margin: auto;
    margin-top: 49px;
    .pic {
      width: 510px;
      height: 454px;
      position: relative;
      margin-top: 30px;
      display: inline;
      white-space: nowrap;
      margin-right: 6.56%;
    }

    .pic-txt {
      width: 787px;
      height: 390px;
      display: flex;
      position: relative;
      white-space: nowrap;
      justify-content: space-between;
      padding-top: 3.95%;
      .left-pic-txt,
      .right-pic-txt {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: space-between;
      }
      .special {
        width: 345px;
        height: 165px;
        background: #f4f3ef;
        box-shadow: 0px 4px 10px 0px rgba(225, 211, 211, 0.25);
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 49px;
        height: 48px;
        margin-right: 18px;
        padding: 10px;
      }
      span {
        font-size: 30px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 28px;
        margin-top: 10px;
      }
    }
  }

  .peo {
    width: 100%;
    height: 100%;
    background: #da007f;
    display: flex;
    align-items: center;
    margin-top: 89px;
    .left-img-box {
      // padding-left: 19%;
      padding-top: 31px;
      margin-right: 141px;
      .top-text {
        display: flex;
        align-items: center;
        .icon-img {
          height: 90px;
        }
        div {
          height: 56px;
          font-size: 40px;
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          color: #ffffff;
          line-height: 47px;
          margin-left: 17px;
        }
      }
      .bottom-img {
        width: 688px;
        height: 426px;
      }
    }
    .right-info {
      flex: 1;
      padding-left: 36px;
      .info-item {
        .info-title {
          display: flex;
          align-items: center;
          margin-bottom: 22px;
          margin-top: 30px;
          img {
            height: 45px;
          }
          div {
            height: 45px;
            font-size: 32px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 45px;
            margin-left: 18px;
          }
        }
        .info-txt {
          font-size: 20px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 23px;
        }
      }
    }

    .peo-pic span {
      text-align: center;
      vertical-align: middle;
      font-size: 40px;
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 47px;
      margin-left: 356px;
    }
    .peo-pic .peo-txt {
      width: 688px;
      height: 426px;
    }
    .peo-txt img {
      width: 688px;
      height: 426px;
    }
  }
  .peo-tit {
    width: 30px;
    height: 30px;
    background: #da007f;
    display: flex;
    vertical-align: middle;
    img {
      width: 38px;
      height: 38px;
      vertical-align: middle;
    }
    div {
      height: 45px;
      font-size: 30px;
      height: 26px;
      font-size: 22px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 26px;
      margin-left: 30px;
      vertical-align: middle;
    }

    .shop-txt {
      width: 567px;
      font-size: 20px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 23px;
      position: relative;
    }
  }
  .platform {
    width: 100%;
    margin: 25px;
  }
  .platform .we {
    display: flex;
    justify-content: center;
    align-content: center;
    background: #ffffff;
    font-size: 32px;
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    color: #000000;
    line-height: 38px;
    padding-top: 60px;
  }
  .top-item-contoan {
    width: 1340px;
    display: flex;
    // padding-left: 16.56%;
    margin: auto;
    padding-left: 100px
  }
  .top-item {
    margin-top: 63px;
    margin-right: 284px;
    .topicon {
      width: 54px;
      height: 54px;
    }
    .toptxt {
      width: 96px;
      font-size: 24px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #858585;
      line-height: 28px;
      margin-top: 25px;
    }
    .t-icon {
      width: 54px;
      height: 54px;
      margin: 0 auto;
    }
  }
  .business {
    width: 1282px;
    height: 102px;
    margin: auto;
    font-size: 24px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    line-height: 28px;
    margin-top: 60px;
    padding: 0 16.67% 0 16.56%;
    text-indent: 2em;
  }
  .big {
    margin-top: 135px;
    width: 100%;
    height: 300px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    align-items: center;
    .big-icon{
      position: relative;
      width: 462px;
      height: 300px;
      border-radius: 0px 0px 0px 0px;
      opacity: 0.71;
    }
    .big-icon img {
     width: 100%;
     height: 100%;
      position: absolute;
    }
    .enterprise {
      display: flex;
      font-size: 48px;
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 56px;
      // margin-left: 742px;
      margin: auto;
    }
  }
  .tupian-box {
    display: flex;
    // padding-left: 17.01%;
    width: 1450px;
    margin: auto;
    margin-top: 61px;
    align-items: flex-end;
    .img-1 {
      width: 456px;
      height: 720px;
    }
    .tupian-middle-box {
      width: 456px;
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .img-2,
      .img-3 {
        width: 456px;
        height: 375px;
      }
      .img-3 {
        margin-top: 20px;
      }
    }
    .tupian-right-box {
      width: 456px;
      height: 770px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .img-4 {
        width: 456px;
        height: 480px;
      }
      .img-5 {
        width: 456px;
        height: 263px;
      }
    }
  }
  .bottomIcon {
    width: 100vw;
    height: 694px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom-box {
    margin-top: 85px;
    width: 100%;
    height: 200px;
    background: #da007f;
    text-align: center;
    padding: 50px 0 35px;
    box-sizing: border-box;
    .custom {
      font-size: 36px;
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 42px;
    }

    .register {
      width: 33.74%;
      margin: 15px auto 0;
      border: 1px solid #ffffff;
      height: 50px;
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.5);
      overflow: hidden;
      display: flex;
      align-items: center;
      input {
        flex: 1;
        border: 0;
        background: transparent;
        font-size: 20px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        padding-left: 27px;
        &::placeholder {
          color: #ffffff;
        }
      }
      .free {
        width: 200px;
        height: 46px;
        background: #ffffff;
        border-radius: 30px;
        line-height: 46px;
        font-size: 20px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
      }
    }
  }
  .main1 {
    // width: 1396px;
    margin: auto;
    display: flex;
  }
  .main3 {
    width: 1450px;
    margin: auto;
    display: flex;
  }
}
</style>
