import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);


const routes = [
  
  {
    path: "/",
    redirect: '/home', // 访问跟路径重定向到home
    // component: () => import("../views/Main.vue"),
  },
  {
    path: "/home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/AboutUs",
    name:"AboutUs",
    component: () => import("../views/AboutUs.vue")
  },
  {
    path: "/details",
    name:"details",
    component: () => import("../views/detailsView.vue")
  },
 
  {
    path:'/Newview',
    name:'Newview',
    component: () => import("../views/Newsview.vue"),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
